<template>
  <v-card
    flat
    class="position-toolbar d-inline-flex flex-column pa-2 rounded-0"
  >
      <v-btn plain :riple="false" icon v-for="(link, i) in socials" :key="`RRSSToolbar-${i}`">
        <a :href="link.link" target="_blank"><v-img :src="link.min" max-width="48px" contain/></a>
      </v-btn>       

  </v-card>
</template>

<script>
export default {
  name: 'RRSSToolbar',
  props: {
    socials: {
      type : Array,
      default: () => []
    }
  }
}
</script>

<style>
.position-toolbar {
  z-index: 10 !important;
  right: 0 !important;
  top: 40% !important;
  position: fixed !important;
}
</style>